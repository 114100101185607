import React from "react"
import {Link} from "gatsby"
// import {Link, StaticQuery, graphql} from 'gatsby'
import styles from "./Header.module.scss"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import logo from "../images/dt_red.png"
import twitter_logo from "../images/twitter-white3.png"
import dt_logo from "../images/dt_logo.png"
import dt_alerts_banner from "../images/dt_banner.png"

  // <div className ={styles.container}>

  // <Nav.Link href="/euros/">Euros</Nav.Link>

const Header = () => (
<>
    <div>
      <img
        src={dt_alerts_banner} alt="dt_alerts_banner"
        width="100%"
      />
    </div>
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark"  sticky="top" style ={{margin:"0 0 20px 0"}}>
      <Navbar.Brand href="/">
        <img
          src={logo} alt="Logo"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
        &nbsp; Dream Team Alerts
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">

        </Nav>
        <Nav>
          <Nav.Link href="http://fplalerts.com/">FPL Alerts</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link href="https://twitter.com/dt_alerts">
            <img
              src={twitter_logo} alt="twitter_logo"
              width="30"
              height="30"
            />{' '}
          </Nav.Link>
          <Nav.Link href="https://www.dreamteamfc.com/">
            <img
              src={dt_logo} alt="dt_logo"
              width="24"
              height="30"
            />{' '}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

</>
)

export default Header
