import React from 'react'
import styles from './Footer.module.scss'

const Footer = () => (
    <footer className ={styles.footer}>
        <div className={styles.container}>
            <span className="text-muted"> FPL Alerts 2020 </span>
        </div>
    </footer>
)

export default Footer;
